import React from "react"
import { graphql } from "gatsby"
import { INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import IframeContainer from "../components/iframcontainer"

import Layout from "../components/layout"

export const query = graphql`
  query ($slug: String!) {
    contentfulArticulos(slug: { eq: $slug }) {
      titulo
      createdAt(formatString: "DD-MM-yyyy")
      autor
      traductor
      cuerpo {
        raw
      }
      referenciasTeoricas {
        raw
      }
      notas {
        raw
      }
    }
  }
`

const Blog = props => {
  const optionsBody = {
    renderNode: {
      [INLINES.HYPERLINK]: link => {
        if (link.data.uri.includes("player.vimeo.com/video")) {
          return (
            <IframeContainer>
              <iframe
                title="Unique Title 001"
                src={link.data.uri}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          )
        } else if (link.data.uri.includes("youtube.com/embed")) {
          return (
            <IframeContainer>
              <iframe
                title="Unique Title 002"
                src={link.data.uri}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          )
        } else if (link.data.uri.includes("drive.google.com")) {
          return (
            <IframeContainer>
              <iframe
                title="Unique Title 002"
                src={link.data.uri}
                allowFullScreen
              ></iframe>
            </IframeContainer>
          )
        } else {
          return <a href={link.data.uri}>{link.content[0].value}</a>
        }
      },
    },
  }

  const optionsNotes = {
    renderNode: {
      [INLINES.HYPERLINK]: link => {
        if (link.data.uri.includes("#nota_")) {
          return (
            <span id={link.data.uri.slice(1)}>{link.content[0].value}</span>
          )
        } else {
          return <a href={link.data.uri}>{link.content[0].value}</a>
        }
      },
    },
  }

  return (
    <Layout>
      <h2 className="post-title">{props.data.contentfulArticulos.titulo}</h2>
      <header className="post-header">
        <div className="post-author-container">
          <p className="post-author">
            {props.data.contentfulArticulos.autor &&
              props.data.contentfulArticulos.autor.map((a, i, arr) => {
                if (arr.length > 1) {
                  return i !== arr.length - 1 ? a + ", " : " y "
                } else {
                  return a
                }
              })}
          </p>
          <p className="post-date">
            <time>{props.data.contentfulArticulos.createdAt}</time>
          </p>
        </div>
        <div className="post-translator">
          {props.data.contentfulArticulos.traductor &&
            "Traducción: " +
              props.data.contentfulArticulos.traductor.map((t, i, arr) => {
                if (arr.length > 1) {
                  return i !== arr.length - 1 ? t + ", " : " y "
                } else {
                  return t
                }
              })}
        </div>
      </header>

      <div className="post-body">
        {props.data.contentfulArticulos.cuerpo &&
          renderRichText(props.data.contentfulArticulos.cuerpo, optionsBody)}
      </div>
      {props.data.contentfulArticulos.referenciasTeoricas && (
        <div className="post-sources">
          <h4>Referencias teóricas</h4>
          {renderRichText(
            props.data.contentfulArticulos.referenciasTeoricas,
            {}
          )}
        </div>
      )}
      {props.data.contentfulArticulos.notas && (
        <div className="post-notes">
          <h4>Notas</h4>
          {renderRichText(props.data.contentfulArticulos.notas, optionsNotes)}
        </div>
      )}
    </Layout>
  )
}

export default Blog

export const Head = props => {
  return <title>{props.data.contentfulArticulos.titulo}</title>
}
